import http, { devhttp } from "./http";
import { format, parseISO } from "date-fns";

export const getProvisioningSystems = () => {
  return devhttp.get("/list").then((response) => {
    return response.data
      .map((el) => Object.keys(el)[0])
      .map((el, index) => {
        return {
          label: el,
          value: el,
          createdAt: format(
            parseISO(response.data[index][el].ec2_launch_time),
            "MMMM dd, yyyy K:mm aaa",
          ),
          users: [
            {
              userId: "0053t000007KnOlAAK",
              userName: "support@activeprime.com",
              email: "support@activeprime.com",
              firstName: "ActivePrime",
              lastName: "Support",
              permissionName: "ActivePrime_Admin",
            },
          ],
        };
      });
  });
};

export const getTotpSecretKey = async () => {
  return http
    .$get("/settings/otp_key/", {
      headers: {
        Authorization: `token:${localStorage.getItem('apiKey')}`,
      },
    })
    .then((response) => {
      return {
        totpSecretKeyId: response.id,
        totpSecretKey: response.value,
      };
    });
};
