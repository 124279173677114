<template>
  <v-btn
    color="ap-orange"
    :loading="isRedirecting"
    :append-icon="mdiLogoutVariant"
    @click="clear"
  >
    Sign out
  </v-btn>
</template>

<script setup>
import { mdiLogoutVariant } from "@mdi/js";
import { ref } from "vue";

const isRedirecting = ref(false);

function clear() {
  isRedirecting.value = true;
  localStorage.removeItem("env");
  localStorage.removeItem("jwt");
  localStorage.removeItem("authJson");
  window.location.reload();
}
</script>
