import http from "./http";
import {
  colorByScore,
  colorByIssue,
  colorByObject,
  otherColorForObject,
} from "../constants";

export const getMeta = async () => {
  const response = await http.$get("dashboard/metadata");

  if (!Object.keys(response).length > 0) {
    return;
  }

  const issueTypes = {};

  response.issue_types.forEach((el) => {
    issueTypes[el.name] = {
      label: el.label,
      color: colorByIssue[el.name],
    };
  });

  const objects = {};

  response.entities.forEach((el) => {
    objects[el.name] = {
      name: el.name,
      label: el.label,
      color: colorByObject[el.name.toLowerCase()]
        ? colorByObject[el.name.toLowerCase()]
        : otherColorForObject.splice(0, 1)[0],
    };
  });

  const rcs = {};

  response.rcs_map.forEach((el) => {
    rcs[el.name] = {
      label: el.label,
      color: colorByScore[el.name],
    };
  });

  const directors = {};

  Object.keys(response.directors).forEach((directorId) => {
    directors[directorId] = {
      name: response.directors[directorId].name,
      objects: response.directors[directorId].objects,
    };
  });

  return {
    issueTypes,
    objects,
    rcs,
    directors,
  };
};

export const getSemanticTypes = async () => {
  return http.$get("/datasystem/metadata/semantic_types");
}

export const getFields = async ({ flushCache = false, object} = {}) => {
  const options = {
    params: {
      flush: flushCache,
      object
    }
  }

  const response = await http.$get("/datasystem/metadata/fields", options);

  const metadata = {};

  Object.keys(response).forEach((object) => {
    metadata[object] = { list: [], obj: {} };

    response[object].forEach((field) => {
      // Duplicate Set Grid need labels, not the values
      let valuesJustLabels = [];
      if (field.picklist_values) {
        valuesJustLabels = field.picklist_values.map((el) => el.label);
      }

      const mappedField = {
        name: field.name,
        label: field.label,
        isWritable: field.is_writable,
        isSearchable: field.is_searchable,
        displayType: field.display_type,
        values: field.picklist_values,
        valuesJustLabels,
        maxLength: field.constraints?.max_length,
        isRequired: field.constraints?.required,

        semanticType: field.semantic_type || undefined,
        isUpdatable: field.is_updateable,
        isMandatory: field.is_mandatory,
        herokuType: field.db_type,
      };

      metadata[object].list.push(mappedField);
      metadata[object].obj[field.name] = mappedField;
    });
  });

  return metadata;
};

export const getFieldsPerMultiMap = async () => {
  const response = await http.$get("/datasystem/metadata/multimap");

  const metadata = {};

  Object.keys(response).forEach((multiMapId) => {
    metadata[multiMapId] = { list: [], obj: {} };

    response[multiMapId].forEach((field) => {
      // Duplicate Set Grid need labels, not the values
      let valuesJustLabels = [];
      if (field.picklist_values) {
        valuesJustLabels = field.picklist_values.map((el) => el.label);
      }

      const mappedField = {
        name: field.name,
        labelRule: field.label_rule,
        labelGrid: field.label_grid,
        isWritable: field.is_writable,
        isSearchable: field.is_searchable,
        type: field.type,
        displayType: field.display_type,
        values: field.picklist_values,
        valuesJustLabels,
        objects: field.objects,
        maxLength: field.constraints?.max_length,
        isRequired: field.constraints?.required,
      };

      metadata[multiMapId].list.push(mappedField);
      metadata[multiMapId].obj[field.name] = mappedField;
    });
  });

  return metadata;
};

export const getFieldOperators = async () => {
  let response = await http.$get("/datasystem/metadata/operators");

  response.junkExport = {
    Boolean: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false }
    ],
    Date: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false },
      { name: "in", label: "in", needs_value: true },
      { name: "not_in", label: "not in", needs_value: true },
    ],
    Float: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false },
      { name: "in", label: "in", needs_value: true },
      { name: "not_in", label: "not in", needs_value: true },
    ],
    Integer: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false },
      { name: "in", label: "in", needs_value: true },
      { name: "not_in", label: "not in", needs_value: true },
    ],
    Picklist: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false },
      { name: "in", label: "in", needs_value: true },
      { name: "not_in", label: "not in", needs_value: true },
    ],
    Text: [
      { name: "=", label: "equals", needs_value: true },
      { name: "is_junk", label: "is identified as junk", needs_value: false },
      { name: "in", label: "in", needs_value: true },
      { name: "not_in", label: "not in", needs_value: true },
      { name: "contains", label: "contains", needs_value: true },
      { name: "does_not_contain", label: "does not contain", needs_value: true },
    ]
  }

  const metadata = {};

  response.Filter.Textarea = response.Filter.Text;
  response.Primary.Textarea = response.Primary.Text;
  response.RollUp.Textarea = response.RollUp.Text;
  response.Write.Textarea = response.Write.Text;
  response.junkExport.Textarea = response.junkExport.Text;

  Object.keys(response).forEach((ruleType) => {
    metadata[ruleType] = {};

    if (ruleType === "Matching") {
      metadata.Matching = { ...response.Matching };
      metadata.Matching.Textarea = metadata.Matching.Text;
    } else {
      Object.keys(response[ruleType]).forEach((displayType) => {
        metadata[ruleType][displayType] = { list: [], obj: {} };

        if (["Filter", "Primary", "RollUp", "Write"].includes(ruleType)) {
          const isEqualsSupported = response[ruleType][displayType].some(
            (operator) => operator.name === "=",
          );
          const isNotEqualsSupported = response[ruleType][displayType].some(
            (operator) => operator.name === "!=",
          );

          if (isEqualsSupported) {
            const isNull = {
              name: "is_null",
              label: "is null",
              needsValue: false,
            };

            metadata[ruleType][displayType].obj[isNull.name] = isNull;
            metadata[ruleType][displayType].list.push(isNull);
          }

          if (isNotEqualsSupported) {
            const isNotNull = {
              name: "is_not_null",
              label: "is not null",
              needsValue: false,
            };

            metadata[ruleType][displayType].obj[isNotNull.name] = isNotNull;
            metadata[ruleType][displayType].list.push(isNotNull);
          }
        }

        response[ruleType][displayType].forEach((operator) => {
          const mappedOperator = {
            name: operator.name,
            label: operator.label,
            needsValue: operator.needs_value,
          };

          metadata[ruleType][displayType].list.push(mappedOperator);
          metadata[ruleType][displayType].obj[operator.name] = mappedOperator;
        });
      });
    }
  });

  return metadata;
};

export const getConvertedStatus = async () => {
  const response = await http.$get("/datasystem/converted_status");

  return response.map((el) => {
    return {
      name: el.name,
      label: el.label,
    };
  });
};

export const getAssignmentUsersAndGroups = async () => {
  const response = await http.$get("/datasystem/users");

  const usersObj = {};
  const users = []
  
  response.users.forEach((user) => {
    const item = {
      id: user.id,
      username: user.user_name,
      isActive: user.status === "enabled"
    };

    usersObj[item.id] = item;
    users.push(item);
  });

  const groupsObj = {};
  const groups = response.groups.map((group) => {
    const item = {
      type: group.type,
      label: group.label,
    };

    groupsObj[item.type] = item;

    return item;
  });

  return {
    users: {
      list: users,
      obj: usersObj,
    },
    groups: {
      list: groups,
      obj: groupsObj,
    },
  };
};

export const getSalesforceUsers = async () => {
  const response = await http.$get("datasystem/users?salesforce=true");

  return response.users.map(user => {
    return {
      id: user.ds_user_id,
      username: user.user_name,
    }
  })
}
