<script setup>
import DevAuthMessageDialog from "@/components/dev/DevAuthMessageDialog.vue";
import { onMounted, ref } from "vue";

const isAuthDialogVisible = ref(false);

onMounted(async () => {
  const authJson = JSON.parse(localStorage.getItem('authJson'))
  if (authJson) {
    window.postMessage(authJson, "*");
  } else {
    isAuthDialogVisible.value = true;
  }
});
</script>

<template>
  <v-app>
    <DevAuthMessageDialog v-model="isAuthDialogVisible"></DevAuthMessageDialog>
  </v-app>
</template>
